<template>
  <div class="page-body">
    <div class="page-body-content">
      <div class="selection-part-one">
        <titleBar title="全新数智化EMS2.0" :other="true"></titleBar>
        <div class="content">
          EMS2.0依托于我司自有专利的“物联边缘操作系统”技术创新研发，由全新EMS控制器、全新云平台、全新储能管理方案三大核心部分组成。
        </div>
      </div>
    </div>
    <div class="page-body-container">
      <div class="page-body-container-head">
        <div class="head-title-box">
          <div class="head-title-box-item" :class="active == 1 ? 'active' : ''" @click="onTabChange(1)">
            <img class="emsImg" src="https://cdn.lnxall.com/img/EMSController.png" alt="">
            EMS控制器
          </div>
          <div class="head-title-box-item" :class="active == 2 ? 'active' : ''" @click="onTabChange(2)">
            <img class="cloudImg" src="https://cdn.lnxall.com/img/cloud.png" alt="">
            智汇云储
          </div>
          <div class="head-title-box-item" :class="active == 3 ? 'active' : ''" @click="onTabChange(3)">
            <img class="schemeImg" src="https://cdn.lnxall.com/img/scheme.png" alt="">
            全新储能管理方案
          </div>
        </div>
        <template v-if="active == 1">
          <div class="head-title-content">EMS控制器采用高度集成一体化硬件设计、全国产工业级芯片，并加持自有专利的嵌入式低代码物联边缘操作系统。</div>
          <img class="ems-products-img" src="https://cdn.lnxall.com/img/EMSControllerPro.png" alt="">
        </template>
        <template v-if="active == 2">
          <div class="head-title-content">智汇云储由前台驾驶舱和后台管理两部分组成，兼顾大屏数据展示与批量操作管理，可进行深入智能化分析，实现系统排障更精准和收益策略最优化。</div>
          <img class="cloud-products-img" src="https://cdn.lnxall.com/img/cloudPro.png" alt="">
        </template>
        <template v-if="active == 3">
          <div class="head-title-content">全新储能管理方案首创了对等模式，用户可自行选择主从或对等模式，从容应对复杂落地场景，进一步增强了系统的灵活性和可靠性。</div>
          <img class="scheme-products-img" src="https://cdn.lnxall.com/img/schemePro.png" alt="">
        </template>
      </div>
    </div>
    <div class="advantage" v-if="active == 1">
      <div class="advantage-item">
        <img class="hardware-img" src="https://cdn.lnxall.com/img/iconHardware.png" alt="">
        <div class="advantage-item-title"><span>硬件优势</span></div>
        <div class="advantage-item-content">
          <div class="item-card">
            <p>自研硬件和国产工业级芯片</p>
            <p>提升数据处理能力</p>
          </div>
          <div class="item-card">
            <p>本地存储大量数据</p>
            <p>保障安全</p>
          </div>
          <div class="item-card">
            <p>低功耗</p>
            <p>提高系统能效和收益</p>
          </div>
          <div class="item-card">
            <p>小尺寸、低成本、丰富接口</p>
            <p>安装便捷</p>
          </div>
        </div>
      </div>
      <div class="advantage-item">
        <img class="software-img" src="https://cdn.lnxall.com/img/iconSoftware.png" alt="">
        <div class="advantage-item-title"><span>软件优势</span></div>
        <div class="advantage-item-content">
          <div class="item-card">
            <p>离线数据分析，提供细致数据</p>
            <p>支持系统优化</p>
          </div>
          <div class="item-card">
            <p>零配置自组网</p>
            <p>简化落地，降低成本</p>
          </div>
          <div class="item-card">
            <p>整柜智能诊断</p>
            <p>快速排查隐患，定位故障</p>
          </div>
          <div class="item-card">
            <p>批量配置固件</p>
            <p>提高生产效率</p>
          </div>
        </div>
      </div>
    </div>
    <div class="cloudES" v-if="active == 2">
      <div class="cloudES-item">
        <img src="https://cdn.lnxall.com/img/RCEBG.png" alt="">
        <div class="cloudES-item-title">降本增效</div>
        <div class="cloudES-item-content">
          <p>满足生产、调试、运维等不同需求。</p>
          <p>实现高效批量集控、极致数据压缩和细致层级权限管理。</p>
        </div>
      </div>
      <div class="cloudES-item">
        <img src="https://cdn.lnxall.com/img/thoughtBG.png" alt="">
        <div class="cloudES-item-title">便捷运维</div>
        <div class="cloudES-item-content">
          <p>深入告警日志分析，全面巡检预警</p>
          <p>强大远程调试，智能 BI 分析</p>
        </div>
      </div>
      <div class="cloudES-item">
        <img src="https://cdn.lnxall.com/img/cloudESBG.png" alt="">
        <div class="cloudES-item-title">智能运维</div>
        <div class="cloudES-item-content">
          <p>收益估算与预测</p>
          <p>AI优化策略，资产监测与评估</p>
        </div>
      </div>
    </div>
    <div class="scheme-container" v-if="active == 3">
      <div class="scheme-container-item">
        <img class="deploy-ornament-img" src="https://cdn.lnxall.com/img/flex-deployment-dec.png" alt="">
        <div class="scheme-container-item-title">部署灵活</div>
        <img class="deploy-flexible-img" src="https://cdn.lnxall.com/img/flex-deployment.png" alt="">
        <div class="scheme-container-item-content">无需捆绑，<br />可实现三方设备多元配置</div>
      </div>
      <div class="scheme-container-item">
        <img class="mode-ornament-img" src="https://cdn.lnxall.com/img/mode-selection-dec.png" alt="">
        <div class="scheme-container-item-title card-title-left">模式自选</div>
        <img class="mode-flexible-img" src="https://cdn.lnxall.com/img/mode-selection.png" alt="">
        <div class="scheme-container-item-content card-content-left">首创对等模式，<br />从容应对复杂落地场景</div>
      </div>
      <div class="scheme-container-item">
        <img class="microgrid-ornament-img" src="https://cdn.lnxall.com/img/support-microgrid-dec.png" alt="">
        <div class="scheme-container-item-title">支持微网</div>
        <img class="microgrid-flexible-img" src="https://cdn.lnxall.com/img/support-microgrid.png" alt="">
        <div class="scheme-container-item-content">提供完善的微网控制管理能力</div>
      </div>
      <div class="scheme-container-item">
        <img class="func-ornament-img" src="https://cdn.lnxall.com/img/functional-dec.png" alt="">
        <div class="scheme-container-item-title card-title-left">功能全包</div>
        <img class="func-flexible-img" src="https://cdn.lnxall.com/img/functional.png" alt="">
        <div class="scheme-container-item-content card-content-left">从设计到运维的端到端<br />全链条服务支撑</div>
      </div>
    </div>
  </div>
</template>

<script>
import titleBar from "@/components/titleBar";
export default {
  name: "intellect-page",
  components: {
    titleBar,
  },
  data() {
    return {
      active: 1,
      cardactive: true,
    };
  },
  mounted() {},
  filters: {},
  created() {},
  methods: {
    onTabChange(value) {
      this.active = value;
    },
  },
  destroyed() {},
};
</script>
<style scoped lang="less">
.page-body {
  box-sizing: border-box;
  background-color: #ffffff;
  .page-body-content {
    margin: 0 auto;
    box-sizing: border-box;
    .selection-part-one {
      padding: 40px 15px 20px 15px;
      .content {
        overflow-wrap: break-word;
        color: #222222;
        font-size: 14px;
        letter-spacing: 1px;
        line-height: 24px;
        text-align: left;
        margin-top: 20px;
      }
    }
  }
  &-container {
    width: 100%;
    height: 380px;
    background: #F4F8F8;
    box-sizing: border-box;
    &-head {
      margin: 0 auto;
      box-sizing: border-box;
      position: relative;
      .head-title-box {
        margin: 0 auto;
        width: 100%;
        height: 72px;
        display: flex;
        justify-content: space-around;
        align-items: center;
        &-item {
          font-weight: 400;
          font-size: 12px;
          color: #888888;
          border-radius: 45px;
          padding: 8px 10px;
          cursor: pointer;
          .emsImg {
            width: 12px;
            height: 13px;
            vertical-align: -3px;
            margin-right: 3px;
          }
          .cloudImg {
            width: 14px;
            height: 15px;
            vertical-align: -4px;
            margin-right: 3px;
          }
          .schemeImg {
            width: 14px;
            height: 12px;
            vertical-align: -2px;
            margin-right: 3px;
          }
        }
        .active {
          font-weight: 500;
          color: #222222;
          background: #FFFFFF;
          box-shadow: 8px 0px 40px 0px rgba(183,188,205,0.17);
          
        }
      }
      .head-title-content {
        padding: 0 15px;
        font-weight: 400;
        font-size: 14px;
        color: #222222;
        letter-spacing: 1px;
        line-height: 24px;
      }
      .ems-products-img {
        width: 100%;
        position: absolute;
        top: 110px;
        left: 50%;
        transform: translateX(-50%);
      }
      .cloud-products-img {
        width: 90%;
        position: absolute;
        top: 200px;
        left: 50%;
        transform: translateX(-50%);
      }
      .scheme-products-img {
        width: 96%;
        position: absolute;
        top: 180px;
        left: 50%;
        transform: translateX(-50%);
      }
    }
  }
  .advantage {
    display: flex;
    flex-direction: column;
    align-items: center;
    box-sizing: border-box;
    width: 100%;
    background: url('https://cdn.lnxall.com/img/whiteTexture.png') no-repeat;
    background-size: 100% 100%;
    &-item {
      margin-top: 30px;
      display: flex;
      flex-direction: column;
      align-items: center;
      .hardware-img {
        width: 25px;
        height: 19px;
      }

      .software-img {
        width: 20px;
        height: 20px;
      }

      &-title {
        width: 140px;
        margin-top: 10px;
        font-family: PingFangSC, PingFang SC;
        font-weight: 500;
        font-size: 18px;
        color: #222222;
        letter-spacing: 1px;
        text-align: center;
        background: url("https://cdn.lnxall.com/img/titleEmbellishment.png") no-repeat;
        background-size: 100% 100%;
        span {
          display: inline-block;
          margin-left: 3px;
          vertical-align: -6px;
        }
      }

      &-content {
        display: flex;
        flex-wrap: wrap;
        padding: 15px 15px;
        gap: 10px;
        .item-card {
          display: flex;
          flex-direction: column;
          justify-content: center;
          width: 100%;
          height: 120px;
          box-shadow: 0px 16px 24px 0px rgba(183,188,205,0.2);
          border-radius: 8px;
          background: url("https://cdn.lnxall.com/img/unselected-background.png") -24px -8px no-repeat;
          p {
            font-family: PingFangSC, PingFang SC;
            font-weight: 400;
            font-size: 14px;
            color: #555555;
            line-height: 2px;
            text-align: center;
          }
        }
      }
    }
  }
  .cloudES {
    display: flex;
    flex-direction: column;
    align-items: center;
    box-sizing: border-box;
    width: 100%;
    padding: 15px;
    &-item {
      width: 100%;
      height: 145px;
      position: relative;
      box-sizing: border-box;
      img {
        width: 100%;
        height: 150px;
      }
      &-title {
        position: absolute;
        top: 28px;
        left: 100px;
        font-family: PingFangSC, PingFang SC;
        font-weight: 500;
        font-size: 16px;
        color: #222222;
        text-align: center;
      }
      &-content {
        padding-right: 10px;
        position: absolute;
        top: 58px;
        left: 100px;
        font-family: PingFangSC, PingFang SC;
        font-weight: 400;
        font-size: 14px;
        color: #555555;
        p {
          margin: 5px 0px;
        }
      }
    }
  }
  .scheme-container {
    &-item:nth-child(odd) {
      background: linear-gradient(to top, #F9FFFE 0%, #FFFFFF 100%);
    }
    &-item:nth-child(even) {
      background: #F4F8F8;
    }
    &-item {
      width: 100%;
      height: 160px;
      box-sizing: border-box;
      position: relative;
      .deploy-ornament-img {
        width: 51px;
        height: 39px;
        position: absolute;
        top: 15px;
        left: 140px;
      }
      &-title {
        position: absolute;
        top: 40px;
        left: 185px;
        font-family: PingFangSC, PingFang SC;
        font-weight: 500;
        font-size: 18px;
        color: #247777;
        line-height: 42px;
      }
      .deploy-flexible-img {
        width: 130px;
        height: 130px;
        position: absolute;
        top: 20px;
        left: 18px;
      }
      &-content {
        position: absolute;
        top: 80px;
        left: 185px;
        font-family: PingFangSC, PingFang SC;
        font-weight: 400;
        font-size: 14px;
        color: #555555;
        line-height: 30px;
      }
      .card-title-left {
        top: 40px;
        left: 55px;
      }
      .card-content-left {
        left: 55px;
      }
      .mode-ornament-img {
        width: 49px;
        height: 37px;
        position: absolute;
        top: 15px;
        left: 15px;
      }
      .mode-flexible-img {
        width: 133px;
        height: 124px;
        position: absolute;
        top: 22px;
        right: 18px;
      }
      .microgrid-ornament-img {
        width: 63px;
        height: 51px;
        position: absolute;
        top: 12px;
        left: 135px;
      }
      .microgrid-flexible-img {
        width: 180px;
        height: 153px;
        position: absolute;
        top: 7px;
        left: 15px;
      }
      .func-ornament-img {
        width: 50px;
        height: 44px;
        position: absolute;
        top: 10px;
        left: 15px;
      }
      .func-flexible-img {
        width: 147px;
        height: 134px;
        position: absolute;
        top: 22px;
        right: 18px;
      }
    }
  }
}
</style>
